import { useSummary } from '@client/PerformanceClient';
import { formatDate } from '@shared/formatDate';
import {
  IPerformanceSummary,
  PerformanceSummaryToken,
} from '@shared/performance';
import {
  performanceAccomplishmentsQuestion,
  performanceStrengthsQuestion,
} from '@shared/questions';
import { PageContent } from '@web/app/Page';
import { ErrorPageContent } from '@web/components/ErrorPageContent';
import { Markdown } from '@web/components/Markdown';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Column } from '@web/components/layout';
import { Header2 } from '@web/components/typography';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useParams } from 'react-router-dom';

export const ViewSummaryPage: React.FC = () => {
  const { performanceSummaryToken } = useParams<{
    performanceSummaryToken: PerformanceSummaryToken;
  }>();
  if (!performanceSummaryToken) {
    return <ErrorPageContent />;
  }

  const { data: summary } = useSummary(performanceSummaryToken);
  if (!summary) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  return <ViewSummary summary={summary} />;
};

export const ViewSummary: React.FC<{ summary?: IPerformanceSummary }> = ({
  summary,
}) => {
  const existingStrengthResponse = summary?.responses?.find(
    (response) => response.questionToken === performanceStrengthsQuestion.token,
  );
  const existingAccomplishmentsResponse = summary?.responses?.find(
    (response) =>
      response.questionToken === performanceAccomplishmentsQuestion.token,
  );

  // TODO: figure out best way to format dates like 2024-04-24 to Apr 24
  // without undesirable timezone effects. Currently this would be problematic
  // for any timezone east of UTC
  const parseDate = (date: string) => new Date(`${date}T23:59:59.999Z`);
  return (
    <PageContent>
      <PageHeader
        title={`${summary?.user.name}`}
        description={`Summary of work between ${formatDate(
          parseDate(summary?.startDate as any),
        )} and ${formatDate(parseDate(summary?.endDate as any))}`}
        navigateBack
      />
      <Column gap={24} style={{ maxWidth: 800 }}>
        <Pane>
          <Column gap={12}>
            <Header2>{performanceAccomplishmentsQuestion.text}</Header2>
            <Markdown value={existingAccomplishmentsResponse?.response ?? ''} />
          </Column>
        </Pane>
        <Pane>
          <Column gap={12}>
            <Header2>{performanceStrengthsQuestion.text}</Header2>
            <Markdown value={existingStrengthResponse?.response ?? ''} />
          </Column>
        </Pane>
      </Column>
    </PageContent>
  );
};

import { SurveyType, SurveyTypeLabels } from '@shared/surveys';
import { FeedbackType, ITask, TaskType, UserMapItem } from '@shared/types';
import { isManagerOf } from '@shared/users';
import {
  Colors,
  complimentaryColor,
  primaryColor,
  successColor,
} from '@web/app/styles/ColorStyles';
import Color from 'color';
import { capitalize } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

export const TaskTag: React.FC<{
  task: ITask;
  receiver: UserMapItem;
  declined: boolean;
}> = ({ task, receiver, declined }) => {
  const feedbackRequest = task.feedbackRequests?.[0];
  const isManagerRequest =
    feedbackRequest && isManagerOf(receiver, feedbackRequest.requesterToken);
  const isCycleRequest = !!task.reflection?.reviewCycleToken;

  if (declined) {
    return <Tag color="#888">Declined Request</Tag>;
  } else if (task.completedDate) {
    let taskText = 'Feedback Given';
    if (task.type === TaskType.SUGGESTED_ENTRY) {
      taskText = 'Entry Added';
    } else if (task.type === TaskType.REFLECTION) {
      taskText = isCycleRequest ? 'Review Complete' : 'Reflection Complete';
    } else if (task.type === TaskType.SURVEY) {
      taskText = 'Submitted';
    }

    return <Tag color="#888">{taskText}</Tag>;
  } else if (feedbackRequest?.type === FeedbackType.UPWARD) {
    return <Tag color={Colors.supernova}>Upward Feedback Request</Tag>;
  } else if (isManagerRequest) {
    return (
      <Tag color="#b135b1">
        {isManagerRequest && 'Manager '}Feedback Request
      </Tag>
    );
  } else if (task.type === TaskType.SUGGESTED_ENTRY) {
    return <Tag color={complimentaryColor.string()}>Suggested Entry</Tag>;
  } else if (task.type === TaskType.REFLECTION) {
    const isSelfReview =
      task.reflection.authorToken === task.reflection.receiverToken;

    return (
      <Tag color="#3cbf3c">
        {isSelfReview
          ? 'Self Review'
          : isCycleRequest
          ? 'Manager Review'
          : 'Reflection'}
      </Tag>
    );
  } else if (task.type === TaskType.SURVEY) {
    const color =
      task.surveyCycle.type === SurveyType.BASIC
        ? complimentaryColor.string()
        : successColor.string();
    return (
      <Tag color={color}>
        {capitalize(SurveyTypeLabels[task.surveyCycle.type])}
      </Tag>
    );
  }

  return (
    <Tag color={primaryColor.toString()}>
      {isManagerRequest && 'Manager '}Feedback Request
    </Tag>
  );
};
const Tag = styled.span<{ color: string }>`
  background-color: ${(props) => new Color(props.color).lighten(0.9)};
  border: 1px solid ${(props) => new Color(props.color).toString()};
  color: ${(props) => new Color(props.color).darken(0.2)};
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  padding: 0 6px;
  border-radius: 8px;
  text-transform: uppercase;
  margin: 2px 0;
  white-space: nowrap;
`;

import {
  createPerformanceSummary,
  generatePerformanceSummary,
  performanceEntries,
  useSummary,
} from '@client/PerformanceClient';
import { IChatCompletion } from '@shared/completions';
import {
  IPerformanceSummary,
  PerformanceSummaryToken,
} from '@shared/performance';
import {
  performanceAccomplishmentsQuestion,
  performanceStrengthsQuestion,
} from '@shared/questions';
import { IUser, UserToken } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useDefault as usePartial } from '@web/common/useApi';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { SelectDateRange } from '@web/components/SelectDate';
import { Column, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Skeleton, message } from 'antd';
import { addMonths, format } from 'date-fns';
import humanparser from 'humanparser';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import removeMarkdown from 'remove-markdown';

import { SelectUserFromTeams } from './SelectUserFromTeams';

export const NewSummaryPage: React.FC = () => {
  const { performanceSummaryToken } = useParams<{
    performanceSummaryToken: PerformanceSummaryToken;
  }>();
  const { data: summary } = performanceSummaryToken
    ? useSummary(performanceSummaryToken)
    : usePartial<IPerformanceSummary>({});
  if (!summary) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  return <NewSummary summary={summary} />;
};

export const NewSummary: React.FC<{ summary?: IPerformanceSummary }> = ({
  summary,
}) => {
  const navigate = useNavigate();
  const [generating, setGenerating] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(summary?.user ?? null);
  const [entriesJson, setEntriesJson] = React.useState(
    summary?.entriesJson ?? '',
  );
  const [startDate, setStartDate] = React.useState(
    summary?.startDate ?? format(addMonths(new Date(), -3), 'yyyy-MM-dd'),
  );
  const [endDate, setEndDate] = React.useState(
    summary?.endDate ?? format(new Date(), 'yyyy-MM-dd'),
  );

  const reloadEntries = async (
    userToken: UserToken,
    journalStartDate: string,
    journalEndDate: string,
  ) => {
    const entries = await performanceEntries(
      userToken,
      journalStartDate,
      journalEndDate,
    );
    const json = JSON.stringify(
      entries.map((entry) => ({
        Title: entry.title,
        Description: removeMarkdown(entry.descriptionMarkdown),
        Date: entry.date,
      })),
      ' ' as any,
      2,
    );
    setEntriesJson(json);
  };

  const handleGenerateResponses = async () => {
    if (generating) {
      return;
    }

    setGenerating(true);
    void message.loading('Working...', 0);
    try {
      const [completion1, completion2] = await Promise.all([
        handleGenerateQuestion1(),
        handleGenerateQuestion2(),
      ]);
      await saveSummary(completion1, completion2);
      void message.destroy();
    } catch (error) {
      void message.destroy();
      void message.error(error.message ?? 'Error');
    } finally {
      setGenerating(false);
    }
  };

  const handleGenerateQuestion1 = async () => {
    if (!selectedUser) {
      throw new Error();
    }

    const { firstName } = humanparser.parseName(selectedUser.name);
    return await generatePerformanceSummary(
      selectedUser.token,
      DEFAULT_QUESTION1_PROMPT(firstName as any),
      entriesJson,
    );
  };
  const handleGenerateQuestion2 = async () => {
    if (!selectedUser) {
      throw new Error();
    }

    const { firstName } = humanparser.parseName(selectedUser.name);
    return await generatePerformanceSummary(
      selectedUser.token,
      DEFAULT_QUESTION2_PROMPT(firstName as any),
      entriesJson,
    );
  };

  const saveSummary = async (
    question1Completion: IChatCompletion,
    question2Completion: IChatCompletion,
  ) => {
    if (!selectedUser) {
      void message.error('Error');
      return;
    }

    const { firstName } = humanparser.parseName(selectedUser.name);
    const summary = await createPerformanceSummary(
      {
        startDate,
        endDate,
        entriesJson,
        userToken: selectedUser.token,
      },
      [
        {
          questionToken: performanceAccomplishmentsQuestion.token,
          completionToken: question1Completion.token,
          response: question1Completion.response ?? '',
          prompt: DEFAULT_QUESTION1_PROMPT(firstName as any),
        },
        {
          questionToken: performanceStrengthsQuestion.token,
          completionToken: question2Completion.token,
          response: question2Completion.response ?? '',
          prompt: DEFAULT_QUESTION2_PROMPT(firstName as any),
        },
      ],
    );
    navigate(`../${summary.token}`);
    void message.success('Summary saved');
  };

  const handleSelectUser = (user: IUser | null) => {
    setSelectedUser(user);
    if (user) {
      void reloadEntries(user.token, startDate, endDate);
    }
  };
  const handleDateRangeChanged = (newStart: string, newEnd: string) => {
    setStartDate(newStart);
    setEndDate(newEnd);
    if (selectedUser?.token) {
      void reloadEntries(selectedUser.token, newStart, newEnd);
    }
  };

  const canGenerate = !!selectedUser && !!startDate && !!endDate;
  return (
    <PageContent>
      <PageHeader title={summary?.name ?? 'New Summary'} navigateBack />
      <Pane>
        <Column style={{ maxWidth: 300 }}>
          <Text>User</Text>
          <SelectUserFromTeams
            onChange={(_, user) => {
              void handleSelectUser(user);
            }}
          />
          <Spacer size={12} />
          <Text>Date Range</Text>
          <SelectDateRange
            start={startDate}
            end={endDate}
            onChange={handleDateRangeChanged}
          />
          <Spacer />
          <Button
            type="primary"
            disabled={!canGenerate}
            onClick={handleGenerateResponses}
          >
            Generate
          </Button>
        </Column>
      </Pane>
    </PageContent>
  );
};

const DEFAULT_QUESTION1_PROMPT = (
  firstName: string,
) => `Using the list of my work described in the JSON file, group the work into projects. Determine the two projects that represent the biggest, most important accomplishments. Write a performance review that summarizes each accomplishment in about 100 words, including a heading and a few examples for each. Write the review in the third person using their name, ${firstName}.
  The format of the review should be:
  **{Brief description of first accomplishment}**
  {Description and examples of first accomplishment}
  **{Brief description of second accomplishment}**
  {Description and examples of second accomplishment}
  Here is a list of work completed, in JSON:
  `;
const DEFAULT_QUESTION2_PROMPT = (
  firstName: string,
) => `Using the list of the work that this person completed described in the JSON file, determine their 2 or 3 biggest strengths. Focus on skills and capabilities that they should use in future work. Summarize each strength in a few bolded words, followed by a longer description that is about 100 words. Write the response in the third person using their name, ${firstName}. 
  The format of the response should be:
  1.**{summary of first strength}**
  {description of first strength}
  2.**{summary of second strength}**
  {description of second strength}
  3.**{summary of third strength}**
  {description of third strength}
  Here is a list of work completed, in JSON:
  `;

import { ISurveyCycle, ISurveyParticipant } from '@shared/surveys';
import { PageContent } from '@web/app/Page';
import { useApi } from '@web/common/useApi';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { useOutstandingCount } from '@web/common/useOutstandingCount';
import { PageHeader } from '@web/components/PageHeader';
import { Pane } from '@web/components/Pane';
import { Max, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Skeleton } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TakeSurvey } from './TakeSurvey';

interface ISurveyDetails {
  surveyCycle: ISurveyCycle;
  participant: ISurveyParticipant;
}

export const TakeSurveyPage: React.FC = () => {
  const { reloadOutstandingTaskCount } = useOutstandingCount();
  const navigate = useNavigate();
  const navigateBackOrGoto = useNavigateBack();
  const { surveyCycleToken } = useParams();
  const { data: surveyDetails, mutate: reloadSurveyDetails } =
    useApi<ISurveyDetails>(`/survey-cycles/${surveyCycleToken}/details`);

  const handleSurveyComplete = () => {
    void reloadOutstandingTaskCount();
    void reloadSurveyDetails();
    navigateBackOrGoto('/requests');
  };

  if (!surveyDetails?.surveyCycle) {
    return (
      <PageContent>
        <PageHeader
          title={surveyDetails?.surveyCycle.name ?? ''}
          mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
          navigateBack
          defaultNavigateBackTo="/requests"
        />
        <Skeleton />
      </PageContent>
    );
  }

  if (surveyDetails.participant.submittedDate) {
    navigate('view', { replace: true });
  }

  return (
    <PageContent>
      <PageHeader
        title={surveyDetails?.surveyCycle.name ?? ''}
        mobileTitle={surveyDetails?.surveyCycle.name ?? ''}
        navigateBack
        defaultNavigateBackTo="/requests"
      />
      <Pane>
        <Max width="800px">
          {surveyDetails.surveyCycle.endedDate ? (
            <Text>Survey is no longer active</Text>
          ) : (
            <>
              <Text style={{ fontStyle: 'italic' }}>
                {surveyDetails.surveyCycle.anonymous
                  ? 'This survey is anonymous.'
                  : 'Your responses will be shared with your manager.'}
              </Text>
              <Spacer size={12} />
              <TakeSurvey
                surveyCycle={surveyDetails.surveyCycle}
                participant={surveyDetails.participant}
                onComplete={handleSurveyComplete}
              />
            </>
          )}
        </Max>
      </Pane>
    </PageContent>
  );
};

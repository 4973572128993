import { Button } from '@slack/types';

import { MessageShortcut } from '../slack/types';
import { InputAction } from './InteractionsHandler';

// https://api.slack.com/reference/interaction-payloads/block-actions
export interface Interaction {
  type: string;
  trigger_id: string;
  user: SlackUser;
  api_app_id: string;
}

export type KnownInteraction =
  | BlockActionsInteraction
  | InteractiveMessageInteraction
  | MessageShortcut
  | ViewSubmission;

export interface ViewSubmission extends Interaction {
  type: 'view_submission';
  view: {
    id: string;
    external_id: string;
    callback_id: string;
    private_metadata?: string;
    state: {
      values: any;
    };
  };
}

export interface ViewClosed extends Interaction {
  type: 'view_closed';
  view: {
    id: string;
    external_id: string;
    callback_id: string;
    private_metadata?: string;
    state: {
      values: any;
    };
  };
}

export interface InteractiveMessageInteraction extends Interaction {
  type: 'interactive_message';
  container: InteractionContainer;
}
export interface BlockActionsInteraction extends Interaction {
  type: 'block_actions';
  user: SlackUser;
  actions: Array<Button | InputAction>;
  message: Message;
  container: InteractionContainer;
  view?: {
    id: string;
    external_id: string;
    callback_id: string;
    private_metadata?: string;
    state: {
      values: any;
    };
  };
}

export interface SlackUser {
  id: string;
  username: string;
  name: string;
  team_id: string;
}

export function isMessageShortcut(
  interaction: KnownInteraction,
): interaction is MessageShortcut {
  return interaction.type === 'message_action';
}

export enum ButtonAction {
  CREATE_ENTRY = 'CREATE_ENTRY',
  SELECT_PEERS = 'SELECT_PEERS',
  APPROVE_PEERS = 'APPROVE_PEERS',
  GIVE_FEEDBACK_ACTION = 'GIVE_FEEDBACK_ACTION',
  GIVE_UPWARD_FEEDBACK = 'GIVE_UPWARD_FEEDBACK',
  GIVE_ENTRY_FEEDBACK_ACTION = 'GIVE_ENTRY_FEEDBACK_ACTION',
  SUBMIT_REFLECTION = 'SUBMIT_REFLECTION',
  SUBMIT_CYCLE_REFLECTION = 'SUBMIT_CYCLE_REFLECTION',
  SELECT_PULL_REQUESTS = 'SELECT_PULL_REQUESTS',
  SUGGEST_ENTRY = 'SUGGEST_ENTRY',
  SHARE_REVIEWS = 'SHARE_REVIEWS',
  REQUEST_DIMENSION_FEEDBACK = 'REQUEST_DIMENSION_FEEDBACK',
  TAKE_SURVEY = 'TAKE_SURVEY',
  DECLINE_FEEDBACK_REQUEST = 'DECLINE_FEEDBACK_REQUEST',
  CHECK_IN_ADD_AGENDA_ITEM = 'CHECK_IN_ADD_AGENDA_ITEM',
}

export function isBlockAction(
  interaction: Interaction,
): interaction is BlockActionsInteraction {
  return interaction.type === 'block_actions';
}

export function isViewSubmission(
  interaction: Interaction,
): interaction is ViewSubmission {
  return interaction.type === 'view_submission';
}

export enum ViewCallbackId {
  SELECT_PEERS_MODAL = 'SELECT_PEERS_MODAL',
  APPROVE_PEERS_MODAL = 'APPROVE_PEERS_MODAL',
  CREATE_ENTRY_MODAL = 'CREATE_ENTRY_MODAL',
  TAKE_SURVEY_MODAL = 'TAKE_SURVEY_MODAL',
  GIVE_FEEBACK_MODAL = 'GIVE_FEEBACK_MODAL',
  GIVE_UPWARD_FEEBACK_MODAL = 'GIVE_UPWARD_FEEBACK_MODAL',
  GIVE_ENTRY_FEEBACK_MODAL = 'GIVE_ENTRY_FEEBACK_MODAL',
  SUBMIT_REFLECTION_MODAL = 'SUBMIT_REFLECTION_MODAL',
  SUBMIT_CYCLE_REFLECTION_MODAL = 'SUBMIT_CYCLE_REFLECTION_MODAL',
  SELECT_PULL_REQUESTS_MODAL = 'SELECT_PULL_REQUESTS_MODAL',
  SUGGEST_ENTRY_MODAL = 'SUGGEST_ENTRY_MODAL',
  REQUEST_DIMENSION_FEEDBACK_MODAL_STEP_DIMENSIONS = 'REQUEST_DIMENSION_FEEDBACK_MODAL_STEP_DIMENSIONS',
  REQUEST_DIMENSION_FEEDBACK_MODAL_STEP_GIVERS_AND_CONTEXT = 'REQUEST_DIMENSION_FEEDBACK_MODAL_STEP_GIVERS_AND_CONTEXT',
  REQUEST_DIMENSION_FEEDBACK_MODAL_STEP_CONFIRM = 'REQUEST_DIMENSION_FEEDBACK_MODAL_STEP_CONFIRM',
}

/**
 * These values correspond to values configured on the Flint app itself on Slack
 * DO NOT CHANGE!!!
 */
export enum FlintMessageShortcut {
  CREATE_JOURNAL_ENTRY = 'create_journal_entry', // No seriously, don't change this
}

export interface InteractionContainer {
  type: string;
  message_ts: string;
  channel_id: string;
  is_ephemeral: boolean;
}

export interface Message {
  bot_id: string;
  type: string;
  text: string;
  user: string;
  ts: string;
  app_id: string;
  blocks: [
    {
      type: string;
      block_id: string;
      text: {
        type: string;
        text: string;
        emoji: true;
      };
    },
    {
      type: string;
      block_id: string;
      elements: [
        {
          type: string;
          action_id: string;
          text: {
            type: string;
            text: string;
            emoji: true;
          };
          style: string;
          value: string;
        },
      ];
    },
  ];
  team: string;
}
